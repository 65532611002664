import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import PreAuthApp from './App';
import { AuthProvider, AuthProviderProps } from "react-oidc-context";
import themes from 'devextreme/ui/themes';
import { CookiesProvider } from 'react-cookie';


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

fetch(`${process.env.PUBLIC_URL}/environment/authentication.json`)
  .then((response) => response.json())
  .then((oidcConfig) => {
    const config: AuthProviderProps = {
      ...oidcConfig, 
      onSigninCallback: () => {
        window.history.replaceState({}, document.title, window.location.pathname);
      }
    };

    themes.initialized(() => root.render(
      <AuthProvider {...config}>
        <CookiesProvider>
          <React.StrictMode>
            <PreAuthApp />
          </React.StrictMode>
        </CookiesProvider>
      </AuthProvider>
    ));
    
  })
  .catch((err) => {
    console.error(err);
    root.render(
      <div className="failedResponse">
        Missing authentication configuration.
      </div>
    );
  });

